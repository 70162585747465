<template>
    <div class="scorebar row gx-3 gy-0">
        <div class="col-auto scorebar__min">
            {{ min }}
        </div>
        <div class="col">
            <div class="scorebar__bar">
                <div class="scorebar__bar__background"></div>
                <div class="scorebar__bar__foreground" :style="`width: ${100 - percentage}%`"></div>
                <div class="scorebar__bar__grid"
                     @mouseleave="valueHover.value = null"
                >
                    <template v-for="num in numGutters" :key="num">
                        <span :class="{'bordered': num < numGuttersBordered, 'inp': isInput }"
                              @mouseenter="onMouseEnter(num)"
                              @click="isInput ? value = num : ''"
                        ></span>
                    </template>
                </div>
                <div class="scorebar__bar__label" :style="`width: ${percentage}%`">
                  {{ valuePercentage }}
                </div>
            </div>
        </div>
        <div class="col-auto scorebar__max">
            {{ max }}
        </div>
        <input v-if="isInput" type="hidden" v-model="value">
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'ScoreBar',

  emits: ['update:modelValue'],

  props: {
    min: {
      type: Number,
      required: false,
      default: () => 1,
    },
    max: {
      type: Number,
      required: false,
      default: () => 10,
    },
    modelValue: {
      type: Number,
      required: true,
    },
    isInput: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  setup(props, { emit }) {
    const value = computed({
      set(val) {
        emit('update:modelValue', val);
      },
      get(): number {
        return props.modelValue;
      },
    });

    const valueHover = reactive({ value: null as number | null });
    const valuePercentage = computed(() => valueHover.value || value.value);
    const percentage = computed(() => {
      const val = valuePercentage.value as number;

      return Math.round((val / props.max) * 100);
    });

    const numGutters = computed(() => {
      let gutters = props.max - props.min + 1;

      if (gutters < 0) {
        gutters = 0;
      }

      return gutters;
    });

    const numGuttersBordered = computed(() => {
      const currentVal = value.value as number;
      let gutters = props.isInput ? numGutters.value : (currentVal - 1 + props.min);

      if (gutters < 0) {
        gutters = 0;
      }

      return gutters;
    });

    const onMouseEnter = (num: number): void => {
      if (props.isInput) {
        valueHover.value = num;
      }
    };

    return {
      value,
      valueHover,
      valuePercentage,
      percentage,
      numGutters,
      numGuttersBordered,
      onMouseEnter,
    };
  },
});
</script>

<style lang="scss">
    @import 'src/scss/base';

    .scorebar {
        .milk__scores & {
            --milk-scorebar-spacer-color: rgba(0, 0, 0, .1);
        }
        .milk__scores.dark & {
            --milk-scorebar-spacer-color: rgba(125, 125, 125, .25);
        }
        &__min, &__max {
            @include korolev-condensed-light(20px);
        }

        &__min {
            color: #D3744E;
        }

        &__max {
            color: #B0C459;
        }

        &__bar {
            @include korolev-heavy-italic(16px);
            position: relative;
            overflow: hidden;
            top: 0.1825em;

            &__foreground, &__background {
                height: 1.5625em;
            }

            &__background {
                background: rgb(176,196,89);
                background: linear-gradient(-90deg, rgba(176,196,89,1) 0%, rgba(211,116,78,1) 100%);
            }

            &__foreground {
                position: absolute;
                top: 0;
                right: 0;
                text-align: right;
                background-color: #4e4239;
                transform: skewX(-25deg) scaleX(2) translateX(-.05em);
                transform-origin: left;
            }

            &__grid {
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                display: flex;

                > span {
                    height: 100%;
                    display: block;
                    flex: 1 0 0;
                    position: relative;

                    &.bordered {
                        &:before {
                            display: block;
                            content: '';
                            width: 2px;
                            height: 100%;
                            position: absolute;
                            right: 0;
                            top: 0;
                            background-color: var(--milk-scorebar-spacer-color);
                            transform: skew(-25deg);
                            pointer-events: none;
                        }
                    }

                    &.inp {
                        cursor: pointer;
                    }
                }
            }

            &__label {
                position: absolute;
                left: 0;
                top: 0;
                text-align: right;
                height: 100%;
                color: #FFF;
                padding-right: .875em;
                line-height: 1.5625em;
                pointer-events: none;
            }
        }
    }
</style>
