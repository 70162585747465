<template>
    <input
        v-model="value"
        :type="type"
    />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'Input',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: () => 'text',
    },
  },

  setup(props, { emit }) {
    const value = computed({
      set(val) {
        emit('update:modelValue', val);
      },
      get() {
        return props.modelValue;
      },
    });

    return {
      value,
    };
  },
});
</script>

<style lang="scss">
    @import "src/scss/base";

    .milky-input {
        --milky-input-color: #FFFFFF;

        > input[type] {
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid;
            border-bottom-color: var(--milky-input-color);
            transition: border-bottom-color .35s ease;
            padding: calc(4px + .25em) .5em;

            &, &::placeholder {
                @include korolev-condensed-light(20px);
                color: var(--milky-input-color);
            }

            &::placeholder {
                opacity: .5;
            }

            &:focus {
                outline: 0;
                border-bottom-color: $primary;
            }

            width: 100%;

            &.is-invalid {
                border-bottom-color: $danger;
            }
        }

        &.dark {
            --milky-input-color: #201914;
        }
    }
</style>
