<template>
  <li class="milky-select__option" :class="{ 'selected': selected }">
    <div class="option-label" v-html="label"></div>
  </li>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'SelectOption',

  props: {
    selectedValue: {
      required: true,
    },
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const deepEqual = (x: unknown, y: unknown): boolean => {
      const ok = Object.keys;
      const tx = typeof x;
      const ty = typeof y;

      if (x && y && tx === 'object' && tx === ty) {
        const recordX: Record<string, unknown> = x as Record<string, unknown>;
        const recordY: Record<string, unknown> = y as Record<string, unknown>;

        return ok(recordX).length === ok(recordY).length
        && ok(recordY).every((key) => deepEqual(recordX[key], recordY[key]));
      }

      return x === y;
    };

    const selected = computed(() => (
      props.selectedValue
      && deepEqual(props.value, props.selectedValue)
    ));

    return {
      selected,
    };
  },
});
</script>

<style lang="scss">
  @import "src/scss/base";

  .milky-select__option {
    padding: calc(4px + .25em) .5em;
    @include korolev-condensed-light(20px);
    color: #FFFFFF;
    cursor: pointer;
    background-color: #574c44;

    &.selected, &:hover, &:focus {
      outline: 0;
      background-color: $primary;
    }

    &.selected {
      cursor: default;
    }

    &.is-invalid {
      border-bottom-color: $danger;
    }

    .milky-input.dark & {
      // color: #201914;
    }
  }
</style>
