
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'Input',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: () => 'text',
    },
  },

  setup(props, { emit }) {
    const value = computed({
      set(val) {
        emit('update:modelValue', val);
      },
      get() {
        return props.modelValue;
      },
    });

    return {
      value,
    };
  },
});
