
import {
  defineComponent, computed, ref, reactive, nextTick,
} from 'vue';
import { useStore } from 'vuex';
import MilkInterface from '@/interfaces/MilkInterface';
import InputField from '@/components/InputField.vue';
import MilkyRankPrioModal from './MilkyRankPrioModal.vue';
import MilkyRankMilk from './MilkyRankMilk.vue';
import AnimateScroll from '../utils/AnimateScroll';
import WeightingInterface from '../interfaces/WeightingInterface';

const sortByOptions = [
  {
    label: 'Gesamtwertung',
    value: 'score:asc',
  },
  {
    label: 'Geschmack',
    value: 'taste:asc',
  },
  {
    label: 'Preis',
    value: 'price:asc',
  },
  {
    label: 'Schaum',
    value: 'foam:asc',
  },
  {
    label: 'Konsistenz',
    value: 'texture:asc',
  },
];

const weightingDefaults: WeightingInterface = {
  taste: 10,
  foam: 4,
  texture: 5,
  price: 2,
};

export default defineComponent({
  name: 'MilkRank',

  emits: ['fetched'],

  components: {
    InputField,
    MilkyRankMilk,
    MilkyRankPrioModal,
  },

  setup() {
    const store = useStore();
    const milks = computed(() => store.getters.milks);
    const userConfig = reactive({
      category: 'Alle',
      sortBy: sortByOptions[0],
      weighting: { ...weightingDefaults } as WeightingInterface,
      sortByRanking: 'milky_ranking',
      numShowMilks: 3,
    });
    const sortByKey = computed(() => userConfig.sortBy.value.split(':')[0]);
    const sortByType = computed(() => userConfig.sortBy.value.split(':')[1]);
    const categoryOptions = computed(() => {
      let cOpts = ['Alle'];
      cOpts = cOpts.concat([...milks.value].map((
        milk: MilkInterface,
      ) => milk.categories).flat().filter((
        v: FlatArray<string[][], 1>, i: number, a: unknown[],
      ) => a.indexOf(v) === i));

      return cOpts;
    });
    const ingredients = computed(() => {
      let iOpts = [...milks.value].map((milk: MilkInterface) => milk.ingredients).flat();
      iOpts = iOpts.filter((
        v: FlatArray<string[][], 1>, i: number, a: unknown[],
      ) => a.indexOf(v) === i);

      return iOpts;
    });
    const milksSorted = computed(() => {
      const weighting: Record<string, number> = { ...userConfig.weighting };

      return [...milks.value].filter((milk) => {
        const isAll = userConfig.category === categoryOptions.value[0];
        return isAll || milk.categories.indexOf(userConfig.category) >= 0;
      }).map((milk) => {
        const newMilk = { ...milk };
        const rankings = {
          milky_ranking: { ...newMilk.milky_ranking } as Record<string, number>,
          user_ranking: { ...newMilk.user_ranking } as Record<string, number>,
        };
        const scores: Record<string, number> = {
          milky_ranking: 0,
          user_ranking: 0,
        };

        Object.entries(rankings).forEach(([key, ranking]) => {
          let numWeighting = 0;

          ['taste', 'foam', 'texture', 'price'].forEach((categ) => {
            numWeighting += weighting[categ];
            scores[key] += ranking[categ] * weighting[categ];
          });

          scores[key] = Math.floor((scores[key] / numWeighting) * 2) / 2;
        });

        newMilk.milky_ranking.score = scores.milky_ranking;
        newMilk.user_ranking.score = scores.user_ranking;

        return newMilk;
      }).sort((m1, m2) => {
        const rankings = {
          m1: {
            milky_ranking: { ...m1.milky_ranking } as Record<string, number>,
            user_ranking: { ...m1.user_ranking } as Record<string, number>,
          } as Record<string, {[key: string]: number }>,
          m2: {
            milky_ranking: { ...m2.milky_ranking } as Record<string, number>,
            user_ranking: { ...m2.user_ranking } as Record<string, number>,
          } as Record<string, {[key: string]: number }>,
        };

        const m1RankByKey = rankings.m1[userConfig.sortByRanking][sortByKey.value];
        const m2RankByKey = rankings.m2[userConfig.sortByRanking][sortByKey.value];

        if (sortByType.value === 'asc') {
          if (m1RankByKey < m2RankByKey) {
            return 1;
          }

          if (m1RankByKey > m2RankByKey) {
            return -1;
          }
        } else {
          if (m1RankByKey > m2RankByKey) {
            return 1;
          }

          if (m1RankByKey < m2RankByKey) {
            return -1;
          }
        }

        return 0;
      });
    });

    const canShowMore = computed(() => milksSorted.value.length > userConfig.numShowMilks);
    const canShowLess = computed(() => !(milksSorted.value.length > userConfig.numShowMilks)
      && milksSorted.value.length > 3);

    const prioModal = ref<InstanceType<typeof MilkyRankPrioModal>>();

    const openPrioModal = (): void => {
      if (prioModal.value) {
        prioModal.value.showModal();
      }
    };

    return {
      milks,
      milksSorted,
      sortByKey,
      sortByType,
      categoryOptions,
      ingredients,
      canShowMore,
      canShowLess,
      sortByOptions: [...sortByOptions],
      weightingDefaults: { ...weightingDefaults } as WeightingInterface,
      price: {
        min: null as number | null,
        max: null as number | null,
      },
      animateScroll: new AnimateScroll(),
      showMilks: true,
      prioModal,
      openPrioModal,
      userConfig,
    };
  },

  watch: {
    sortBy() {
      this.showMilks = false;
      nextTick(() => {
        this.showMilks = true;
      });
      this.userConfig.numShowMilks = 3;
    },
    category() {
      this.showMilks = false;
      nextTick(() => {
        this.showMilks = true;
      });
      this.userConfig.numShowMilks = 3;
    },
    sort() {
      this.showMilks = false;
      nextTick(() => {
        this.showMilks = true;
      });
      this.userConfig.numShowMilks = 3;
    },
    weighting: {
      handler() {
        this.showMilks = false;
        nextTick(() => {
          this.showMilks = true;
        });
      },
      deep: true,
    },
  },

  methods: {
    hideMore(): void {
      this.userConfig.numShowMilks = 3;
      setTimeout(() => {
        this.animateScroll.scrollVerticalToElementById('section-rank', 80);
      }, 175);
    },

    updateWeighting(newWeighting: WeightingInterface): void {
      this.userConfig.weighting = { ...newWeighting };
      this.userConfig.numShowMilks = 3;
    },
  },
});
