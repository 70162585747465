<template>
    <div class="milky-input" :class="theme">
        <label
            v-if="label"
            v-html="label"
            :for="id"
        ></label>
        <component :is="inputType"
                   v-model="value"
                   :id="id"
                   :type="type"
                   :options="options"
                   :placeholder="placeholder"
                   :class="{'is-invalid': hasError}"
        ></component>
        <div class="invalid-feedback" v-show="hasError" v-html="errorMessage"></div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import Input from '@/components/inputs/Input.vue';
import Select from '@/components/inputs/Select.vue';

export default defineComponent({
  name: 'InputField',

  components: {
    Input,
    Select,
  },

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      required: false,
    },
    inputType: {
      type: String,
      required: false,
      default: () => 'input',
    },
    label: {
      type: String,
      required: false,
      default: () => null,
    },
    type: {
      type: String,
      required: false,
      default: () => 'text',
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: false,
      default: () => null,
    },
    theme: {
      type: String,
      required: false,
      default: () => 'light',
    },
    errorMessage: {
      type: String,
      required: false,
      default: () => null,
    },
  },

  setup(props, { emit }) {
    const id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8);

    const value = computed({
      set(val) {
        emit('update:modelValue', val);
      },
      get() {
        return props.modelValue;
      },
    });

    const hasError = computed<boolean>(() => props.errorMessage !== null && props.errorMessage !== '');

    return {
      id,
      value,
      hasError,
    };
  },
});
</script>

<style lang="scss">
    @import 'src/scss/base';

    .milky-input {
        label {
            @include korolev-heavy-italic(20px);
            display: block;
        }

        &.light {
            label {
                color: #FFFFFF;
            }
        }
    }
</style>
