
import {
  defineComponent, reactive, ref, onMounted, PropType,
} from 'vue';
import { Modal } from 'bootstrap';
import WeightingInterface from '@/interfaces/WeightingInterface';
import MilkScores from './MilkScores.vue';

export default defineComponent({
  name: 'MilkyRankPrioModal',

  components: {
    MilkScores,
  },
  props: {
    defaults: {
      type: Object as PropType<WeightingInterface>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const root = ref<InstanceType<typeof HTMLElement>>();
    const scores = ref<InstanceType<typeof MilkScores>>();
    const activeScores = reactive<WeightingInterface>({ ...props.defaults });
    const newScores = reactive<WeightingInterface>({ ...props.defaults });
    const modal = reactive({ value: null as Modal | null });

    const showModal = (): void => {
      if (modal.value) {
        modal.value.show();
      }
    };

    const hideModal = (): void => {
      if (modal.value) {
        modal.value.hide();
      }
    };

    const updateScores = (newScoresInp: WeightingInterface): void => {
      newScores.taste = newScoresInp.taste;
      newScores.foam = newScoresInp.foam;
      newScores.texture = newScoresInp.texture;
      newScores.price = newScoresInp.price;
    };

    const cancel = (): void => {
      if (scores.value) {
        scores.value.setValues(activeScores);
      }
      hideModal();
    };

    const emitScores = (): void => {
      activeScores.taste = newScores.taste;
      activeScores.foam = newScores.foam;
      activeScores.texture = newScores.texture;
      activeScores.price = newScores.price;
      emit('update', activeScores);
      hideModal();
    };

    onMounted(() => {
      if (root.value) {
        modal.value = new Modal(root.value);
      }
    });

    return {
      root,
      activeScores,
      newScores,
      scores,
      showModal,
      hideModal,
      updateScores,
      cancel,
      emitScores,
    };
  },
});

