<template>
    <div class="milky-rank">
        <div class="milky-rank__form">
            <div class="row">
                <div class="col-12 col-lg-auto">
                    <InputField
                        v-model="userConfig.category"
                        :options="categoryOptions"
                        label="Milchsorten"
                        input-type="select"
                    ></InputField>
                </div><div class="col-12 col-lg-auto">
                    <div class="sp s d-lg-none"></div>
                    <InputField
                        v-model="userConfig.sortBy"
                        :options="sortByOptions"
                        label="Sortieren nach"
                        input-type="select"
                    ></InputField>
                </div>
                <div class="col-12 col-xl text-center text-lg-start">
                    <div class="sp s d-xl-none"></div>
                    <h6>
                        Finde die perfekte Milch nach deinen Präferenzen.
                    </h6>
                    <button @click="openPrioModal">
                        Los geht's <i class="fal fa-chevron-right"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="milky-rank__spacer"></div>
        <div class="milky-rank__milks" v-if="showMilks">
            <template v-for="(milk, inx) in milksSorted">
                <template v-if="inx < userConfig.numShowMilks">
                    <MilkyRankMilk
                        :key="milk.id"
                        :milk="milk"
                        :ranking-key="userConfig.sortByRanking"
                        :spacer="
                          inx !== milksSorted.length - 1 && inx !== userConfig.numShowMilks - 1
                        "
                        :place="inx + 1"
                    ></MilkyRankMilk>
                </template>
            </template>
        </div>
        <template v-if="canShowMore">
            <div class="sp"></div>
            <div class="milky-rank__milks__more">
                <button @click="userConfig.numShowMilks += 3">
                    Mehr anzeigen<br>
                    <i class="far fa-chevron-down"></i>
                </button>
            </div>
        </template>
        <template v-if="canShowLess">
            <div class="sp"></div>
            <div class="milky-rank__milks__more">
                <button @click="hideMore" class="text-primary">
                    <i class="far fa-chevron-up"></i><br>
                    Weniger anzeigen
                </button>
            </div>
        </template>
        <MilkyRankPrioModal
          ref="prioModal"
          :defaults="weightingDefaults"
          @update="updateWeighting"
        ></MilkyRankPrioModal>
    </div>
</template>

<script lang="ts">
import {
  defineComponent, computed, ref, reactive, nextTick,
} from 'vue';
import { useStore } from 'vuex';
import MilkInterface from '@/interfaces/MilkInterface';
import InputField from '@/components/InputField.vue';
import MilkyRankPrioModal from './MilkyRankPrioModal.vue';
import MilkyRankMilk from './MilkyRankMilk.vue';
import AnimateScroll from '../utils/AnimateScroll';
import WeightingInterface from '../interfaces/WeightingInterface';

const sortByOptions = [
  {
    label: 'Gesamtwertung',
    value: 'score:asc',
  },
  {
    label: 'Geschmack',
    value: 'taste:asc',
  },
  {
    label: 'Preis',
    value: 'price:asc',
  },
  {
    label: 'Schaum',
    value: 'foam:asc',
  },
  {
    label: 'Konsistenz',
    value: 'texture:asc',
  },
];

const weightingDefaults: WeightingInterface = {
  taste: 10,
  foam: 4,
  texture: 5,
  price: 2,
};

export default defineComponent({
  name: 'MilkRank',

  emits: ['fetched'],

  components: {
    InputField,
    MilkyRankMilk,
    MilkyRankPrioModal,
  },

  setup() {
    const store = useStore();
    const milks = computed(() => store.getters.milks);
    const userConfig = reactive({
      category: 'Alle',
      sortBy: sortByOptions[0],
      weighting: { ...weightingDefaults } as WeightingInterface,
      sortByRanking: 'milky_ranking',
      numShowMilks: 3,
    });
    const sortByKey = computed(() => userConfig.sortBy.value.split(':')[0]);
    const sortByType = computed(() => userConfig.sortBy.value.split(':')[1]);
    const categoryOptions = computed(() => {
      let cOpts = ['Alle'];
      cOpts = cOpts.concat([...milks.value].map((
        milk: MilkInterface,
      ) => milk.categories).flat().filter((
        v: FlatArray<string[][], 1>, i: number, a: unknown[],
      ) => a.indexOf(v) === i));

      return cOpts;
    });
    const ingredients = computed(() => {
      let iOpts = [...milks.value].map((milk: MilkInterface) => milk.ingredients).flat();
      iOpts = iOpts.filter((
        v: FlatArray<string[][], 1>, i: number, a: unknown[],
      ) => a.indexOf(v) === i);

      return iOpts;
    });
    const milksSorted = computed(() => {
      const weighting: Record<string, number> = { ...userConfig.weighting };

      return [...milks.value].filter((milk) => {
        const isAll = userConfig.category === categoryOptions.value[0];
        return isAll || milk.categories.indexOf(userConfig.category) >= 0;
      }).map((milk) => {
        const newMilk = { ...milk };
        const rankings = {
          milky_ranking: { ...newMilk.milky_ranking } as Record<string, number>,
          user_ranking: { ...newMilk.user_ranking } as Record<string, number>,
        };
        const scores: Record<string, number> = {
          milky_ranking: 0,
          user_ranking: 0,
        };

        Object.entries(rankings).forEach(([key, ranking]) => {
          let numWeighting = 0;

          ['taste', 'foam', 'texture', 'price'].forEach((categ) => {
            numWeighting += weighting[categ];
            scores[key] += ranking[categ] * weighting[categ];
          });

          scores[key] = Math.floor((scores[key] / numWeighting) * 2) / 2;
        });

        newMilk.milky_ranking.score = scores.milky_ranking;
        newMilk.user_ranking.score = scores.user_ranking;

        return newMilk;
      }).sort((m1, m2) => {
        const rankings = {
          m1: {
            milky_ranking: { ...m1.milky_ranking } as Record<string, number>,
            user_ranking: { ...m1.user_ranking } as Record<string, number>,
          } as Record<string, {[key: string]: number }>,
          m2: {
            milky_ranking: { ...m2.milky_ranking } as Record<string, number>,
            user_ranking: { ...m2.user_ranking } as Record<string, number>,
          } as Record<string, {[key: string]: number }>,
        };

        const m1RankByKey = rankings.m1[userConfig.sortByRanking][sortByKey.value];
        const m2RankByKey = rankings.m2[userConfig.sortByRanking][sortByKey.value];

        if (sortByType.value === 'asc') {
          if (m1RankByKey < m2RankByKey) {
            return 1;
          }

          if (m1RankByKey > m2RankByKey) {
            return -1;
          }
        } else {
          if (m1RankByKey > m2RankByKey) {
            return 1;
          }

          if (m1RankByKey < m2RankByKey) {
            return -1;
          }
        }

        return 0;
      });
    });

    const canShowMore = computed(() => milksSorted.value.length > userConfig.numShowMilks);
    const canShowLess = computed(() => !(milksSorted.value.length > userConfig.numShowMilks)
      && milksSorted.value.length > 3);

    const prioModal = ref<InstanceType<typeof MilkyRankPrioModal>>();

    const openPrioModal = (): void => {
      if (prioModal.value) {
        prioModal.value.showModal();
      }
    };

    return {
      milks,
      milksSorted,
      sortByKey,
      sortByType,
      categoryOptions,
      ingredients,
      canShowMore,
      canShowLess,
      sortByOptions: [...sortByOptions],
      weightingDefaults: { ...weightingDefaults } as WeightingInterface,
      price: {
        min: null as number | null,
        max: null as number | null,
      },
      animateScroll: new AnimateScroll(),
      showMilks: true,
      prioModal,
      openPrioModal,
      userConfig,
    };
  },

  watch: {
    sortBy() {
      this.showMilks = false;
      nextTick(() => {
        this.showMilks = true;
      });
      this.userConfig.numShowMilks = 3;
    },
    category() {
      this.showMilks = false;
      nextTick(() => {
        this.showMilks = true;
      });
      this.userConfig.numShowMilks = 3;
    },
    sort() {
      this.showMilks = false;
      nextTick(() => {
        this.showMilks = true;
      });
      this.userConfig.numShowMilks = 3;
    },
    weighting: {
      handler() {
        this.showMilks = false;
        nextTick(() => {
          this.showMilks = true;
        });
      },
      deep: true,
    },
  },

  methods: {
    hideMore(): void {
      this.userConfig.numShowMilks = 3;
      setTimeout(() => {
        this.animateScroll.scrollVerticalToElementById('section-rank', 80);
      }, 175);
    },

    updateWeighting(newWeighting: WeightingInterface): void {
      this.userConfig.weighting = { ...newWeighting };
      this.userConfig.numShowMilks = 3;
    },
  },
});
</script>

<style lang="scss">
    @import 'src/scss/base';
    @import 'src/scss/components/milky-rank';
    @import 'src/scss/components/expand';

    .milky-rank {
        &__form {
            h6 {
                color: #FFFFFF;

                @include korolev-heavy-italic(20px);

                + button {
                    color: $primary;
                    background: 0;
                    border: 0;
                    outline: 0;
                    @include korolev-condensed-light(20px);
                    padding: .25em .5em .25em 0;

                    svg {
                        margin-left: .5em;
                        transition: margin-left .35s ease;
                    }

                    &:hover {
                        svg {
                            margin-left: .75em;
                        }
                    }
                }
            }

          @media(min-width: map_get($grid-breakpoints, 'lg')) {
            .milky-select {
              width: 15em;
            }
          }
        }
        &__milks__more {
            text-align: center;

            > button {
                border: 0;
                outline: 0;
                background-color: transparent;
                @include korolev-heavy-italic(20px);
                text-align: center;
                color: #FFFFFF;

                svg {
                    margin-left: .5em;
                    transition: margin-left .35s ease;
                }
            }
        }
    }
</style>
