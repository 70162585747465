<template>
  <Hero
    scroll-to-next="about-milky"
    :full-height="true"
  >
    <h2>
      Noch nicht die passende Milchalternative für deinen Kaffeegenuss gefunden?
    </h2>
    <h1>
      Wir helfen dir dabei!
    </h1>
  </Hero>
  <section id="about-milky">
    <section-spacer :spacer="2" type="gap"></section-spacer>
    <div class="sp"></div>
    <div class="container-fluid text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-9 col-xl-8">
          <h2>
            Wie kommt unser Ranking zustande?
          </h2>
          <p>
            In erster Linie haben wir uns überlegt,
            welche Merkmale uns bei unserem veganen Kaffee am wichtigsten sind.
            Da steht der Geschmack weit vorne, gefolgt von Schaum und Konsistenz.
            Der Preis ist hier erstmal zweitrangig.
          </p>
          <p>
            Nachfolgend siehst du das Ranking mit unseren Präferenzen.
            Wenn dir z. B. ein günstiger Preis am wichtigsten ist,
            gefolgt von Geschmack sowie Schaum,
            und somit die Konsistenz zweitrangig ist;
            Dann kannst du ganz schnell dein personalisiertes
            Ranking mit deinen Präferenzen erstellen.
            <b style="cursor: pointer; white-space: nowrap"
               @click="scrollToMilkRank"
            >
              Probiere es doch mal aus!
            </b>
          </p>
        </div>
      </div>
    </div>
    <div class="sp"></div>
    <section-spacer></section-spacer>
  </section>
  <section id="section-rank" class="section-dark">
    <section-spacer type="gap"></section-spacer>
    <div class="sp"></div>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-9">
          <MilkRank></MilkRank>
        </div>
      </div>
    </div>
    <div class="sp"></div>
    <section-spacer></section-spacer>
  </section>
  <section id="share-experience">
    <section-spacer type="gap"></section-spacer>
    <div class="sp"></div>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-9">
          <MilkyRankUserForm></MilkyRankUserForm>
        </div>
      </div>
    </div>
    <div class="sp"></div>
  </section>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import SectionSpacer from '@/components/SectionSpacer.vue';
import Hero from '@/components/Hero.vue';
import AnimateScroll from '@/utils/AnimateScroll';
import MilkRank from '@/components/MilkRank.vue';
import MilkyRankUserForm from '@/components/MilkyRankUserForm.vue';

export default defineComponent({
  name: 'Home',

  components: {
    Hero,
    SectionSpacer,
    MilkRank,
    MilkyRankUserForm,
  },

  setup() {
    const animateScroll = new AnimateScroll();

    const store = useStore();

    const scrollToMilkRank = (): void => {
      animateScroll.scrollVerticalToElementById('section-rank', 80);
    };

    onBeforeMount(() => {
      if (!store.state.milksFetched) {
        store.dispatch('fetch');
      }
    });

    return {
      scrollToMilkRank,
    };
  },
});
</script>
