<template>
    <div class="milk-expand" :class="{expanded: isExpanded.value}">
        <button @click="toggle" :class="type">
            <template v-if="type === 'default'">
                <span v-html="title"></span>
                <span class="text-white icon-rotate">
                    <i :class="icon"></i>
                </span>
                <span></span>
            </template>
            <template v-if="type === 'small'">
                <span class="text-primary" v-html="title"></span>
                <span class="text-primary icon-rotate">
                    <i :class="icon"></i>
                </span>
            </template>
        </button>
        <transition
          name="milk-expand"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
        >
            <div v-show="isExpanded.value">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'MilkExpand',

  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: () => 'fal fa-chevron-down',
    },
    expanded: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    type: {
      type: String,
      required: false,
      default: () => 'default',
    },
  },

  setup(props) {
    const isExpanded = reactive({ value: props.expanded });

    const toggle = (): void => {
      isExpanded.value = !isExpanded.value;
    };

    const enter = (el: HTMLElement): void => {
      const element = el;
      const { width } = getComputedStyle(element);

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      const { height } = getComputedStyle(element);

      element.style.removeProperty('width');
      element.style.removeProperty('position');
      element.style.removeProperty('visibility');
      element.style.height = '0px';

      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    };

    const leave = (el: HTMLElement): void => {
      const leaveElement = el;
      const { height } = getComputedStyle(leaveElement);

      leaveElement.style.height = height;

      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(leaveElement).height;

      requestAnimationFrame(() => {
        leaveElement.style.height = '0px';
      });
    };

    const afterEnter = (el: HTMLElement): void => {
      const afterEnterElement = el;

      afterEnterElement.style.height = 'auto';
    };

    return {
      isExpanded,
      toggle,
      enter,
      leave,
      afterEnter,
    };
  },
});
</script>
