<template>
  <div class="milky-select"
       :class="{ 'open': showOptions.value }"
       ref="root">
    <div ref="inputField" class="milky-select__input">
      <input
        :value="currentOptionLabel"
        @focus="onSelectFocus"
        @focusout="onSelectFocusOut"
        readonly>
      <span>
        <i class="far fa-chevron-down"></i>
      </span>
    </div>
    <div
      class="milky-select__options"
      :class="optionsClasses"
      ref="optionsList"
    >
      <ul>
          <SelectOption
            v-for="(option, inx) in options"
            :key="inx"
            :label="getOptionLabel(option)"
            :value="option"
            @click="setValue(option)"
            :selected-value="value"
          />
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed, defineComponent, ref, reactive, onMounted, onBeforeUnmount, nextTick,
} from 'vue';
import SelectOption from '@/components/inputs/SelectOption.vue';

export default defineComponent({
  name: 'Select',

  emits: ['update:modelValue'],

  components: {
    SelectOption,
  },

  props: {
    modelValue: {
      required: false,
    },
    labelBy: {
      type: String,
      required: false,
      default: () => 'label',
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    maxOptions: {
      type: Number,
      required: false,
      default: () => 10,
    },
  },

  setup(props, { emit }) {
    const root = ref<InstanceType<typeof HTMLElement>>();

    const value = computed({
      set(val) {
        emit('update:modelValue', val);
      },
      get() {
        return props.modelValue;
      },
    });

    const getOptionLabel = (option: unknown): unknown => {
      if (Array.isArray(option) && option.length) {
        return option[0];
      }

      if (typeof option === 'object' && option !== null) {
        const optionObj: {[index: string]:unknown} = { ...option };

        return optionObj[props.labelBy];
      }

      return option as string | number;
    };

    const currentOptionLabel = computed(() => (
      value.value !== null
        ? getOptionLabel(value.value)
        : null
    ));

    const inputField = ref<InstanceType<typeof HTMLElement>>();

    const optionsPosition = reactive({ value: 'bottom' });

    const calculateOptionsMaxHeightTop = (): number => {
      let inputMaxHeightTop = 0;
      const header = document.querySelector('header');
      if (inputField.value) {
        const inputRect = inputField.value.getBoundingClientRect();
        inputMaxHeightTop = inputRect.top
          - (header ? header.offsetHeight : 0);
      }

      return inputMaxHeightTop;
    };

    const calculateOptionsMaxHeightBottom = (): number => {
      let inputMaxHeightBottom = 0;
      if (inputField.value) {
        const inputRect = inputField.value.getBoundingClientRect();
        inputMaxHeightBottom = window.innerHeight
          - inputRect.top
          - inputField.value.offsetHeight;
      }

      return inputMaxHeightBottom;
    };

    const optionsList = ref<InstanceType<typeof HTMLElement>>();
    const showOptions = reactive({ value: false });

    const calculateOptionsMaxHeight = (): number => {
      const maxHeightViewPort = calculateOptionsMaxHeightTop() > calculateOptionsMaxHeightBottom()
        ? calculateOptionsMaxHeightTop()
        : calculateOptionsMaxHeightBottom();

      let optionsListMaxHeight = maxHeightViewPort;

      if (optionsList.value) {
        const listItem = optionsList.value.querySelector('li');

        if (listItem) {
          optionsListMaxHeight = listItem.offsetHeight * props.maxOptions;
        }
      }

      return maxHeightViewPort < optionsListMaxHeight ? optionsListMaxHeight : optionsListMaxHeight;
    };

    const calculateOptionsPosition = (): void => {
      const inputPosTop = calculateOptionsMaxHeightTop();
      const inputPosBottom = calculateOptionsMaxHeightBottom();
      const optionsMaxHeight = calculateOptionsMaxHeight();

      if (optionsMaxHeight < inputPosBottom) {
        optionsPosition.value = 'bottom';
      } else {
        optionsPosition.value = inputPosTop > inputPosBottom ? 'top' : 'bottom';
      }
    };

    const onSelectFocus = (): void => {
      showOptions.value = true;
      nextTick(() => {
        const maxHeight = calculateOptionsMaxHeight();

        if (optionsList.value) {
          optionsList.value.style.maxHeight = `${maxHeight}px`;
        }
      });
    };

    const onSelectFocusOut = (): void => {
      setTimeout(() => {
        showOptions.value = false;
      }, 99);
    };

    const onDocumentClick = ($event: MouseEvent): void => {
      let clickedOutside = false;
      if (root.value && $event.target) {
        clickedOutside = (
          !root.value.contains($event.target as Node)
          && (
            !!root.value
            && !!(
              root.value.offsetWidth
              || root.value.offsetHeight
              || root.value.getClientRects().length
            )
          )
        );
      }

      if (clickedOutside) {
        showOptions.value = false;
      }
    };

    const setValue = (option: unknown): void => {
      value.value = option;

      nextTick(() => {
        showOptions.value = false;
      });
    };

    const optionsClasses = computed(() => [
      `${optionsPosition.value}`,
      showOptions.value ? 'show' : null,
    ]);

    onMounted(() => {
      window.addEventListener('scroll', calculateOptionsPosition);
      window.addEventListener('resize', calculateOptionsPosition);
      window.addEventListener('load', calculateOptionsPosition);
      document.addEventListener('ready', calculateOptionsPosition);
      document.addEventListener('click', (event) => {
        onDocumentClick(event);
      });
      calculateOptionsPosition();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', calculateOptionsPosition);
      window.removeEventListener('resize', calculateOptionsPosition);
      window.removeEventListener('load', calculateOptionsPosition);
      document.removeEventListener('ready', calculateOptionsPosition);
      document.removeEventListener('click', (event) => {
        onDocumentClick(event);
      });
    });

    return {
      value,
      setValue,
      getOptionLabel,
      currentOptionLabel,
      optionsList,
      showOptions,
      onSelectFocus,
      onDocumentClick,
      onSelectFocusOut,
      inputField,
      optionsPosition,
      optionsClasses,
      root,
      calculateOptionsPosition,
    };
  },
});
</script>

<style lang="scss">
  @import "src/scss/base";

  .milky-select {
    position: relative;

    &__input {
      position: relative;
      > input {
        width: 100%;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid;
        border-bottom-color: #FFFFFF;
        transition: border-bottom-color .35s ease;
        padding: calc(4px + .25em) .5em;
        @include korolev-condensed-light(20px);
        color: #FFFFFF;
        cursor: pointer;
        background: transparent;

        &:focus {
          outline: 0;
          border-bottom-color: $primary;
        }

        &.is-invalid {
          border-bottom-color: $danger;
        }

        .milky-input.dark & {
            color: #201914;
            border-bottom-color: #201914;
        }
      }

      > span {
        position: absolute;
        @include font-size(20px);
        padding: calc(4px + .25em) .5em;
        right: 0;
        top: 0;
        color: #FFFFFF;
        pointer-events: none;
        transform: rotate(0deg);
        transition: transform .35s ease;
        will-change: transform;

        .milky-input.dark & {
          color: #201914;
        }
      }
    }

    &.open {
      .milky-select__input {
        > span {
          transform: rotate(180deg);
        }
      }
    }

    &__options {
      overflow: auto;
      position: absolute;
      width: 100%;
      height: auto;
      z-index: 10;
      left: 0;
      display: none;

      &.show {
        display: block;
      }

      > ul {
        list-style: none;
        display: block;
        margin: 0;
        padding: 0;
      }

      &.bottom {
        top: 100%;
      }

      &.top {
        bottom: 100%;
      }
    }
  }
</style>
