
import { defineComponent, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import SectionSpacer from '@/components/SectionSpacer.vue';
import Hero from '@/components/Hero.vue';
import AnimateScroll from '@/utils/AnimateScroll';
import MilkRank from '@/components/MilkRank.vue';
import MilkyRankUserForm from '@/components/MilkyRankUserForm.vue';

export default defineComponent({
  name: 'Home',

  components: {
    Hero,
    SectionSpacer,
    MilkRank,
    MilkyRankUserForm,
  },

  setup() {
    const animateScroll = new AnimateScroll();

    const store = useStore();

    const scrollToMilkRank = (): void => {
      animateScroll.scrollVerticalToElementById('section-rank', 80);
    };

    onBeforeMount(() => {
      if (!store.state.milksFetched) {
        store.dispatch('fetch');
      }
    });

    return {
      scrollToMilkRank,
    };
  },
});
