
import {
  defineComponent, computed, reactive, watch, nextTick,
} from 'vue';
import { useStore } from 'vuex';
import InputField from '@/components/InputField.vue';
import MilkScores from '@/components/MilkScores.vue';
import MilkInterface from '@/interfaces/MilkInterface';
import ScoreInterface from '@/interfaces/ScoreInterface';
import BrandInterface from '@/interfaces/BrandInterface';
import axios from 'axios';
import SubmissionDataInterface from '@/interfaces/SubmissionDataInterface';

export default defineComponent({
  name: 'MilkyRankUserForm',

  components: {
    MilkScores,
    InputField,
  },

  setup() {
    const id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
    const store = useStore();
    const milksByBrand = computed(() => store.getters.byBrand);

    const defaultScores: ScoreInterface = {
      taste: 1,
      foam: 1,
      texture: 1,
    };

    const formData = reactive({
      brand: {
        label: 'Andere Marke',
        value: -1,
      },
      customBrand: null,
      milk: {
        label: 'Andere Milch',
        value: -1,
      },
      customMilk: null,
      email: null,
      ...defaultScores,
    });

    const brandOptions = computed(() => {
      const brandOptionsComputed = [];

      Object.keys(milksByBrand.value).forEach((brandName) => {
        brandOptionsComputed.push({
          label: milksByBrand.value[brandName].name,
          value: milksByBrand.value[brandName].id,
        });
      });

      brandOptionsComputed.push({
        label: 'Andere Marke',
        value: -1,
      });

      return brandOptionsComputed;
    });

    const milkOptions = computed(() => {
      const milkOptionsComputed = [];
      // eslint-disable-next-line max-len
      let filteredBrand!: BrandInterface;

      Object.keys(milksByBrand.value).forEach((brandName) => {
        if (milksByBrand.value[brandName].id === formData.brand.value) {
          filteredBrand = milksByBrand.value[brandName] as BrandInterface;
        }
      });

      if (filteredBrand && filteredBrand.milks) {
        filteredBrand.milks.forEach((milk: MilkInterface) => {
          milkOptionsComputed.push({
            label: milk.name,
            value: milk.id,
          });
        });
      }

      milkOptionsComputed.push({
        label: 'Andere Milch',
        value: -1,
      });

      return milkOptionsComputed;
    });

    const formResponse = reactive({
      successMessage: null,
      errorMessage: null,
      route: null,
      errors: null as {[key: string]: string[]} | null,
    });

    const getErrorMessage = (key: string): string | null => {
      if (formResponse.errors && formResponse.errors[key]) {
        return formResponse.errors[key][0];
      }

      return null;
    };

    const isInvalid = (key: string): boolean => (
      formResponse.errors
        ? Object.prototype.hasOwnProperty.call(formResponse.errors, key)
        : false
    );

    const updateScores = (scores: ScoreInterface): void => {
      formData.taste = scores.taste;
      formData.foam = scores.foam;
      formData.texture = scores.texture;
    };

    const sendSubmission = (): void => {
      const submissionData: SubmissionDataInterface = {
        taste: formData.taste,
        foam: formData.foam,
        texture: formData.texture,
        email: formData.email || '',
      };

      if (formData.brand.value === -1) {
        submissionData.customBrand = formData.customBrand || '';
        submissionData.customMilk = formData.customMilk || '';
      } else {
        submissionData.brand = formData.brand.value;

        if (formData.milk.value === -1) {
          submissionData.customMilk = formData.customMilk || '';
        } else {
          submissionData.milk = formData.milk.value;
        }
      }

      formResponse.successMessage = null;
      formResponse.route = null;
      formResponse.errorMessage = null;
      formResponse.errors = {};

      axios.post(`${process.env.VUE_APP_API_URL}/submission`, submissionData).then(({ data }) => {
        formResponse.successMessage = data.message;
        formResponse.route = data.route;
      }).catch(({ response }) => {
        if (response.data.errors) {
          formResponse.errors = response.data.errors;
        } else {
          formResponse.errorMessage = response.data.message;
        }
      });
    };

    const brandErrorMessage = computed(() => ((formResponse.errors || {}).brand || [null])[0]);
    const milkErrorMessage = computed(() => ((formResponse.errors || {}).milk || [null])[0]);
    const emailErrorMessage = computed(() => ((formResponse.errors || {}).email || [null])[0]);

    watch(() => milksByBrand.value, (newVal) => {
      nextTick(() => {
        const newValKeys = Object.keys(newVal);

        formData.brand = {
          value: newVal[newValKeys[0]].id,
          label: newVal[newValKeys[0]].name,
        };
      });
    });

    watch(() => formData.brand, () => {
      nextTick(() => {
        formData.milk = { ...milkOptions.value[0] };
      });
    });

    return {
      id,
      formData,
      formResponse,
      brandOptions,
      milkOptions,
      defaultScores,
      brandErrorMessage,
      milkErrorMessage,
      emailErrorMessage,
      getErrorMessage,
      isInvalid,
      updateScores,
      milksByBrand,
      sendSubmission,
    };
  },
});
