<template>
    <div class="milk row">
        <div class="col-12 text-center col-lg-auto">
            <div class="milk__rank">
                <span>
                    {{ place }}
                </span>
            </div>
            <div class="sp s"></div>
        </div>
        <div class="col-lg-auto d-none d-lg-block">
            <div class="milk__image">
                <LazyImage
                    :src="src"
                ></LazyImage>
            </div>
        </div>
        <div class="col">
            <div class="row">
              <div class="col-auto d-lg-none">
                <div class="milk__image">
                  <LazyImage
                    :src="src"
                  ></LazyImage>
                </div>
              </div>
              <div class="col col-lg-12">
                <div class="milk__brand" v-html="milk.brand.name"></div>
                <div class="milk__name" v-html="milk.name"></div>
                <div class="row align-items-center g-0">
                  <div class="col align-self-center">
                    <div class="milk__categories" v-html="milk.categories.join(', ')"></div>
                  </div>
                  <div class="w-100 d-lg-none"></div>
                  <div class="col-auto align-self-center">
                    <div class="milk__price"
                         :class="`price-${priceClass}`"
                    >
                        <span>
                            {{ priceFormated }}&thinsp;€
                        </span>
                    </div>
                  </div>
                </div>
                <div class="milk__hr"></div>
              </div>
            </div>
            <div class="sp s d-lg-none"></div>
            <MilkScores :scores="milk[rankingKey]"></MilkScores>
            <div class="sp s"></div>
            <MilkExpand title="Nährwerte">
              <MilkNutritionTable
                :nutritional-values="milk.nutritional_values"
              ></MilkNutritionTable>
            </MilkExpand>
        </div>
        <div v-if="spacer" class="w-100 spacer"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import MilkScores from './MilkScores.vue';
import MilkNutritionTable from './MilkNutritionTable.vue';
import MilkExpand from './MilkExpand.vue';
import LazyImage from './LazyImage.vue';
import MilkInterface from '../interfaces/MilkInterface';

export default defineComponent({
  name: 'MilkyRankMilk',

  components: {
    LazyImage,
    MilkScores,
    MilkNutritionTable,
    MilkExpand,
  },

  props: {
    milk: {
      required: true,
      type: Object as PropType<MilkInterface>,
    },
    rankingKey: {
      required: true,
      type: String,
    },
    place: {
      required: true,
      type: Number,
    },
    spacer: {
      required: true,
      type: Boolean,
    },
  },

  setup(props) {
    const src = computed(() => `/img/milk/${props.milk.brand.name.toLowerCase()}/${props.milk.name.toLowerCase().replace(/ /g, '_')}`);

    const priceClass = computed(() => {
      if (props.milk.milky_ranking.price) {
        if (props.milk.milky_ranking.price <= 3) {
          return 'bad';
        }
        if (props.milk.milky_ranking.price <= 7) {
          return 'ok';
        }
      }

      return 'good';
    });

    const priceFormated = computed(() => props.milk.price.toLocaleString('de-DE', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }));

    return {
      src,
      priceClass,
      priceFormated,
    };
  },
});
</script>

<style scoped>

</style>
