
import { computed, defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'ScoreBar',

  emits: ['update:modelValue'],

  props: {
    min: {
      type: Number,
      required: false,
      default: () => 1,
    },
    max: {
      type: Number,
      required: false,
      default: () => 10,
    },
    modelValue: {
      type: Number,
      required: true,
    },
    isInput: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  setup(props, { emit }) {
    const value = computed({
      set(val) {
        emit('update:modelValue', val);
      },
      get(): number {
        return props.modelValue;
      },
    });

    const valueHover = reactive({ value: null as number | null });
    const valuePercentage = computed(() => valueHover.value || value.value);
    const percentage = computed(() => {
      const val = valuePercentage.value as number;

      return Math.round((val / props.max) * 100);
    });

    const numGutters = computed(() => {
      let gutters = props.max - props.min + 1;

      if (gutters < 0) {
        gutters = 0;
      }

      return gutters;
    });

    const numGuttersBordered = computed(() => {
      const currentVal = value.value as number;
      let gutters = props.isInput ? numGutters.value : (currentVal - 1 + props.min);

      if (gutters < 0) {
        gutters = 0;
      }

      return gutters;
    });

    const onMouseEnter = (num: number): void => {
      if (props.isInput) {
        valueHover.value = num;
      }
    };

    return {
      value,
      valueHover,
      valuePercentage,
      percentage,
      numGutters,
      numGuttersBordered,
      onMouseEnter,
    };
  },
});
