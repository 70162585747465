<template>
    <div ref="root" class="modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Deine Präferenzen</h5>
                    <button type="button" class="btn-close" @click="hideModal" aria-label="Close">
                        <i class="fal fa-times"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <MilkScores ref="scores"
                                 :is-input="true"
                                 :scores="defaults"
                                 :with-price="true"
                                 :can-reset="true"
                                 @update="updateScores"
                    ></MilkScores>
                </div>
                <div class="modal-footer">
                    <div class="modal-footer__btn-group">
                        <button
                          type="button"
                          class="btn-primary"
                          @click="cancel"
                        >
                          <span>Abbrechen</span>
                        </button>
                        <button
                          type="button"
                          class="btn-secondary"
                          @click="emitScores"
                        >
                          <span>Präferenzen anpassen</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {
  defineComponent, reactive, ref, onMounted, PropType,
} from 'vue';
import { Modal } from 'bootstrap';
import WeightingInterface from '@/interfaces/WeightingInterface';
import MilkScores from './MilkScores.vue';

export default defineComponent({
  name: 'MilkyRankPrioModal',

  components: {
    MilkScores,
  },
  props: {
    defaults: {
      type: Object as PropType<WeightingInterface>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const root = ref<InstanceType<typeof HTMLElement>>();
    const scores = ref<InstanceType<typeof MilkScores>>();
    const activeScores = reactive<WeightingInterface>({ ...props.defaults });
    const newScores = reactive<WeightingInterface>({ ...props.defaults });
    const modal = reactive({ value: null as Modal | null });

    const showModal = (): void => {
      if (modal.value) {
        modal.value.show();
      }
    };

    const hideModal = (): void => {
      if (modal.value) {
        modal.value.hide();
      }
    };

    const updateScores = (newScoresInp: WeightingInterface): void => {
      newScores.taste = newScoresInp.taste;
      newScores.foam = newScoresInp.foam;
      newScores.texture = newScoresInp.texture;
      newScores.price = newScoresInp.price;
    };

    const cancel = (): void => {
      if (scores.value) {
        scores.value.setValues(activeScores);
      }
      hideModal();
    };

    const emitScores = (): void => {
      activeScores.taste = newScores.taste;
      activeScores.foam = newScores.foam;
      activeScores.texture = newScores.texture;
      activeScores.price = newScores.price;
      emit('update', activeScores);
      hideModal();
    };

    onMounted(() => {
      if (root.value) {
        modal.value = new Modal(root.value);
      }
    });

    return {
      root,
      activeScores,
      newScores,
      scores,
      showModal,
      hideModal,
      updateScores,
      cancel,
      emitScores,
    };
  },
});

</script>

<style lang="scss">
    @import "src/scss/base";

    .modal {
        .modal-content {
            border-radius: 0;
            background-color: #201914;
            color: #FFFFFF;
            border: 0;
            box-shadow: 0 0 10px rgb(32,25,20);

            .modal-header {
                border-bottom: 0;
            }

            .modal-title {
                @include korolev-heavy-italic(40px);
            }

            .modal-footer {
                border-top: 0;
            }
        }

        .btn-close {
            background: transparent;
            align-self: start;
            color: #FFFFFF;
            line-height: 1;
            border: 0;
            outline: 0;
            @include font-size(20px);
            opacity: 1;

            &:focus, &:hover {
                box-shadow: none;
                color: $primary;
            }
        }

        &-footer {
            overflow: hidden;
            padding: 1rem 1rem 2rem;

            &__btn-group {
                position: relative;
                right: -1rem;
                margin: 0;

                > button {
                    position: relative;
                    overflow: visible;
                    border: none;
                    background-color: transparent;
                    color: #FFFFFF;
                    @include korolev-heavy-italic(20px);
                    padding: 0.6125em 1em;
                    transition: padding-left .35s ease;
                    will-change: padding-left;

                    > span {
                        position: relative;
                        z-index: 2;
                    }

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        transform: skewX(-25deg);
                    }

                    &:focus, &:hover, &:active, &:active:focus {
                        color: #FFFFFF;
                        border: 0;
                        box-shadow: none;
                        background-color: transparent;
                        padding-left: 1.5em;
                    }

                    &:last-child {
                        padding-right: 1rem;

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 120%;
                            height: 100%;;
                            z-index: 1;
                            top: 0;
                            left: 0;
                            transform: skewX(-25deg);
                        }
                    }

                    &.btn-primary {
                        &:before, &:after {
                            background-color: $primary;
                        }
                    }

                    &.btn-secondary {
                        &:before, &:after {
                            background-color: $secondary;
                        }
                    }
                }
            }
        }
    }

    .modal-backdrop {
        background-color: #3d3d3d;
    }
</style>
