
import { defineComponent, PropType } from 'vue';
import NutritionValuesInterface from '@/interfaces/NutritionValuesInterface';

export default defineComponent({
  props: {
    nutritionalValues: {
      required: true,
      type: Object as PropType<NutritionValuesInterface>,
    },
  },

  setup() {
    const getAbbreviationTag = (unit: string): string => {
      let abbrivationTag = '<abbr title="';

      switch (unit) {
        case 'kcal':
          abbrivationTag += 'Kilokalorien';
          break;
        case 'kJ':
          abbrivationTag += 'Kilojoule';
          break;
        default:
          abbrivationTag += 'Gramm';
      }

      abbrivationTag += `">${unit}</abbr>`;

      return abbrivationTag;
    };

    const formatNumber = (num: number, unit = 'g', digits = 1): string => {
      let formattedNum = '';

      if (num < 0) {
        formattedNum = '<&thinsp;';
      }

      const numberFormated = Math.abs(num).toLocaleString('de-DE', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      });

      return `${formattedNum}${numberFormated}&thinsp;${getAbbreviationTag(unit)}`;
    };

    return {
      getAbbreviationTag,
      formatNumber,
    };
  },
});
