<template>
    <div class="table-responsive">
        <table class="nutrition-table table table-sm">
            <tr>
                <th>
                    Energie
                </th>
                <td>
                    <span v-html="formatNumber(nutritionalValues.kj, 'kJ', 0)"></span>
                    /
                    <span v-html="formatNumber(nutritionalValues.kcal, 'kcal', 0)"></span>
                </td>
            </tr>
            <tr>
                <th>
                    Fett<br>
                    davon gesättigte Fettsäuren
                </th>
                <td>
                    <span v-html="formatNumber(nutritionalValues.fat)"></span><br>
                    <span v-html="formatNumber(nutritionalValues.fat_saturated)"></span>
                </td>
            </tr>
            <tr>
                <th>
                    Kohlenhydrate<br>
                    davon Zucker
                </th>
                <td>
                    <span v-html="formatNumber(nutritionalValues.carbohydrates)"></span><br>
                    <span v-html="formatNumber(nutritionalValues.sugar)"></span>
                </td>
            </tr>
            <tr>
                <th>
                    Ballaststoffe
                </th>
                <td>
                    <span v-html="formatNumber(nutritionalValues.fiber)"></span>
                </td>
            </tr>
            <tr>
                <th>
                    Eiweiß
                </th>
                <td>
                    <span v-html="formatNumber(nutritionalValues.protein)"></span>
                </td>
            </tr>
            <tr>
                <th>
                    Salz
                </th>
                <td>
                    <span v-html="formatNumber(nutritionalValues.salt)"></span>
                </td>
            </tr>
        </table>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import NutritionValuesInterface from '@/interfaces/NutritionValuesInterface';

export default defineComponent({
  props: {
    nutritionalValues: {
      required: true,
      type: Object as PropType<NutritionValuesInterface>,
    },
  },

  setup() {
    const getAbbreviationTag = (unit: string): string => {
      let abbrivationTag = '<abbr title="';

      switch (unit) {
        case 'kcal':
          abbrivationTag += 'Kilokalorien';
          break;
        case 'kJ':
          abbrivationTag += 'Kilojoule';
          break;
        default:
          abbrivationTag += 'Gramm';
      }

      abbrivationTag += `">${unit}</abbr>`;

      return abbrivationTag;
    };

    const formatNumber = (num: number, unit = 'g', digits = 1): string => {
      let formattedNum = '';

      if (num < 0) {
        formattedNum = '<&thinsp;';
      }

      const numberFormated = Math.abs(num).toLocaleString('de-DE', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      });

      return `${formattedNum}${numberFormated}&thinsp;${getAbbreviationTag(unit)}`;
    };

    return {
      getAbbreviationTag,
      formatNumber,
    };
  },
});
</script>

<style lang="scss">
    .nutrition-table {
        width: 100%;

        th + td {
            text-align: right;
        }

        abbr[title] {
            text-decoration: none;
        }
    }
</style>
