
import {
  defineComponent, ref, watch, reactive, PropType, onMounted, onBeforeUnmount, computed,
} from 'vue';
import ScoreBar from '@/components/ScoreBar.vue';
import ScoreInterface from '@/interfaces/ScoreInterface';

export default defineComponent({
  name: 'MilkScores',

  components: {
    ScoreBar,
  },

  emits: ['update'],

  props: {
    scores: {
      type: Object as PropType<ScoreInterface>,
      required: false,
      default: () => ({
        taste: 1,
        foam: 1,
        texture: 1,
        price: 1,
      }),
    },
    isInput: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    withPrice: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    canReset: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    theme: {
      type: String,
      required: false,
      default: () => 'light',
    },
  },

  setup(props, context) {
    const labels = {
      taste: 'Geschmack',
      foam: 'Schaum',
      texture: 'Konsistenz',
      price: 'Preis',
    };

    const defaults = { ...props.scores };

    const values = reactive<ScoreInterface>({
      taste: props.scores.taste,
      foam: props.scores.foam,
      texture: props.scores.texture,
      price: props.scores.price,
    });

    const classes = reactive({ value: [] as string[] });

    watch(
      values, (scores: ScoreInterface) => {
        context.emit('update', scores);
      }, { deep: true },
    );

    const restoreDefaults = (): void => {
      values.taste = defaults.taste;
      values.foam = defaults.foam;
      values.texture = defaults.texture;
      values.price = defaults.price;
    };

    const setValues = (newVal: ScoreInterface): void => {
      values.taste = newVal.taste;
      values.foam = newVal.foam;
      values.texture = newVal.texture;
      values.price = newVal.price;
    };

    const isDefault = computed(() => {
      let defaultValues = true;
      const valObj: Record<string, unknown> = { ...values };
      const defaultObj: Record<string, unknown> = { ...defaults };

      Object.entries(valObj).forEach(([key]) => {
        defaultValues = defaultValues && valObj[key] === defaultObj[key];
      });

      return defaultValues;
    });

    const milkScores = ref<InstanceType<typeof HTMLElement>>();

    const checkWidth = (): void => {
      let widthClass = 'small';

      if (milkScores.value) {
        if (milkScores.value.offsetWidth < 540) {
          widthClass = 'small';
        }
      }

      classes.value = [
        props.theme,
        widthClass,
      ];
    };

    onMounted(() => {
      window.addEventListener('resize', checkWidth);
      checkWidth();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', checkWidth);
    });

    return {
      labels,
      defaults,
      values,
      classes,
      milkScores,
      checkWidth,
      restoreDefaults,
      setValues,
      isDefault,
    };
  },
});
