<template>
    <div class="milk__scores" ref="milkScores" :class="classes.value">
        <template v-for="(label, scoreKey) in labels" :key="scoreKey">
            <div
                v-if="scoreKey !== 'price' || withPrice"
                class="milk__scores__category"
            >
                <div class="milk__scores__category__label">
                    {{ label }}
                </div>
                <div>
                    <ScoreBar v-model="values[scoreKey]" :isInput="isInput"></ScoreBar>
                </div>
            </div>
            <div
              class="sp s"
              v-if="
                Object.keys(labels).indexOf(scoreKey)
                < Object.keys(labels).length - (withPrice ? 1 : 2)
              "
              :data-key="scoreKey"
              :data-last-key="Object.keys(labels)[Object.keys(labels).length - (withPrice ? 1 : 2)]"
            ></div>
        </template>
    </div>
    <div class="milk__scores__default" v-if="isInput && canReset">
        <div class="sp s"></div>
        <button @click="restoreDefaults" :class="{'visible': ! isDefault }">
            Standard wiederherstellen <i class="fal fa-sync"></i>
        </button>
    </div>
</template>

<script lang="ts">
import {
  defineComponent, ref, watch, reactive, PropType, onMounted, onBeforeUnmount, computed,
} from 'vue';
import ScoreBar from '@/components/ScoreBar.vue';
import ScoreInterface from '@/interfaces/ScoreInterface';

export default defineComponent({
  name: 'MilkScores',

  components: {
    ScoreBar,
  },

  emits: ['update'],

  props: {
    scores: {
      type: Object as PropType<ScoreInterface>,
      required: false,
      default: () => ({
        taste: 1,
        foam: 1,
        texture: 1,
        price: 1,
      }),
    },
    isInput: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    withPrice: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    canReset: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    theme: {
      type: String,
      required: false,
      default: () => 'light',
    },
  },

  setup(props, context) {
    const labels = {
      taste: 'Geschmack',
      foam: 'Schaum',
      texture: 'Konsistenz',
      price: 'Preis',
    };

    const defaults = { ...props.scores };

    const values = reactive<ScoreInterface>({
      taste: props.scores.taste,
      foam: props.scores.foam,
      texture: props.scores.texture,
      price: props.scores.price,
    });

    const classes = reactive({ value: [] as string[] });

    watch(
      values, (scores: ScoreInterface) => {
        context.emit('update', scores);
      }, { deep: true },
    );

    const restoreDefaults = (): void => {
      values.taste = defaults.taste;
      values.foam = defaults.foam;
      values.texture = defaults.texture;
      values.price = defaults.price;
    };

    const setValues = (newVal: ScoreInterface): void => {
      values.taste = newVal.taste;
      values.foam = newVal.foam;
      values.texture = newVal.texture;
      values.price = newVal.price;
    };

    const isDefault = computed(() => {
      let defaultValues = true;
      const valObj: Record<string, unknown> = { ...values };
      const defaultObj: Record<string, unknown> = { ...defaults };

      Object.entries(valObj).forEach(([key]) => {
        defaultValues = defaultValues && valObj[key] === defaultObj[key];
      });

      return defaultValues;
    });

    const milkScores = ref<InstanceType<typeof HTMLElement>>();

    const checkWidth = (): void => {
      let widthClass = 'small';

      if (milkScores.value) {
        if (milkScores.value.offsetWidth < 540) {
          widthClass = 'small';
        }
      }

      classes.value = [
        props.theme,
        widthClass,
      ];
    };

    onMounted(() => {
      window.addEventListener('resize', checkWidth);
      checkWidth();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', checkWidth);
    });

    return {
      labels,
      defaults,
      values,
      classes,
      milkScores,
      checkWidth,
      restoreDefaults,
      setValues,
      isDefault,
    };
  },
});
</script>

<style lang="scss">
    @import 'src/scss/base';

    .milk__scores {
        width: 100%;
        --milk-scores-label-color: #FFFFFF;

        &.dark {
            --milk-scores-label-color: #201914;
        }

        &.wide {
            display: table;

            > div {
                display: table-row;

                > div {
                    display: table-cell;
                }
            }
        }

        &__category {
            &__label {
                color: var(--milk-scores-label-color);
                @include korolev-heavy-italic(20px);

                .wide & {
                    padding-right: 1.5em;
                    width: 1px;
                }

                & + div {
                  padding: calc(4px + .25em) .5em 0;
                }
            }
        }

        &__default {
            text-align: center;

            > button {
                border: 0;
                outline: 0;
                background-color: transparent;
                @include korolev-condensed-light(16px);
                color: #FFFFFF;
                opacity: 0;
                pointer-events: none;

                &.visible {
                    opacity: 1;
                    pointer-events: all;
                }

                svg {
                    margin-left: .5em;
                    transition: margin-left .35s ease;
                }
            }
        }
    }
</style>
