
import { defineComponent, PropType, computed } from 'vue';
import MilkScores from './MilkScores.vue';
import MilkNutritionTable from './MilkNutritionTable.vue';
import MilkExpand from './MilkExpand.vue';
import LazyImage from './LazyImage.vue';
import MilkInterface from '../interfaces/MilkInterface';

export default defineComponent({
  name: 'MilkyRankMilk',

  components: {
    LazyImage,
    MilkScores,
    MilkNutritionTable,
    MilkExpand,
  },

  props: {
    milk: {
      required: true,
      type: Object as PropType<MilkInterface>,
    },
    rankingKey: {
      required: true,
      type: String,
    },
    place: {
      required: true,
      type: Number,
    },
    spacer: {
      required: true,
      type: Boolean,
    },
  },

  setup(props) {
    const src = computed(() => `/img/milk/${props.milk.brand.name.toLowerCase()}/${props.milk.name.toLowerCase().replace(/ /g, '_')}`);

    const priceClass = computed(() => {
      if (props.milk.milky_ranking.price) {
        if (props.milk.milky_ranking.price <= 3) {
          return 'bad';
        }
        if (props.milk.milky_ranking.price <= 7) {
          return 'ok';
        }
      }

      return 'good';
    });

    const priceFormated = computed(() => props.milk.price.toLocaleString('de-DE', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }));

    return {
      src,
      priceClass,
      priceFormated,
    };
  },
});
